const cardCheckoutEnabled = () => {
  const paymentForm = document.getElementById('payment-form');
  const creditCardSection = document.getElementById('credit-card-section');
  return paymentForm && creditCardSection;
};

const fullDiscountApplied = () => {
  const paymentForm = document.getElementById('payment-form');
  const creditCardSection = document.getElementById('credit-card-section');
  const fullDiscount = paymentForm?.dataset?.fullDiscount === 'true';
  return paymentForm && !creditCardSection && fullDiscount;
};

const creditCardSubmitDisabled = (paymentSubmitBtn) => {
  const creditCardOption = document.getElementById('option_credit_card');
  return !creditCardOption.checked || paymentSubmitBtn.disabled;
};

const ebanxCheckoutEnabled = () => {
  const paymentForm = document.getElementById('payment-form');
  const ebanxEligible = paymentForm?.dataset?.ebanxEligible === 'true';
  return cardCheckoutEnabled() && ebanxEligible;
};

const razorpayCheckoutEnabled = () => {
  const paymentForm = document.getElementById('payment-form');
  return paymentForm?.dataset?.razorpayEligible === 'true';
};

const stripePaymentIntentCheckoutEnabled = () => {
  const paymentForm = document.getElementById('payment-form');
  const stripeIntentEligible = paymentForm?.dataset?.stripeIntentEligible === 'true';
  return stripeIntentEligible;
};

const paymentFormPreventSubmitOnEnterKey = (event) => {
  // We don't want payment submit to occur with a different event than a click.
  // The Enter key could be pressed with other intentions such as applying discounts.
  event.preventDefault();
};

const enableCheckoutSubmitButton = (event) => {
  setTimeout(() => {
    event.target.removeAttribute('disabled');
  }, 1000);
};

const getSelectedPaymentInstallment = () => {
  const checkedInput = document.querySelector('input[name="shop_payment_installment[number_of_installments]"]:checked');

  return checkedInput;
};

const installmentsNumber = () => {
  const checkedInput = getSelectedPaymentInstallment();

  return checkedInput?.value || 1;
};

const disableButton = (button) => {
  button.classList.add('disabled');
};

const enableButton = (button) => {
  button.classList.remove('disabled');
};

const removeFlashErrorMessage = () => {
  const alertDiv = document.querySelector('div.alert-danger');

  if (alertDiv) alertDiv.remove();
};

const displayFlashErrorMessage = (message) => {
  const alertDiv = document.querySelector('div.alert-danger');

  if (alertDiv) {
    alertDiv.innerHTML = message;
  } else {
    const sectionToRender = document.querySelector('section.container') || document.querySelector('.alerts-container');
    const newDiv = document.createElement('div');
    const messageText = document.createTextNode(message);

    newDiv.classList.add('alert', 'alert-danger');
    newDiv.appendChild(messageText);

    sectionToRender.prepend(newDiv);
  }
};

const transactionUUID = () => {
  // transactionUUID first part for Enrollment.
  // transactionUUID second part for Payment Installment.
  const transaction = document.getElementById('shop_payment_installment_transaction_uuid') || document.getElementById('transaction_uuid');

  return transaction?.value;
};

const paymentInstallmentsNotChecked = () => {
  const paymentInstallments = document.getElementsByClassName('form-radio__input');
  const optionsNotChecked = [];

  Array.from(paymentInstallments).forEach((item) => {
    if (item !== getSelectedPaymentInstallment()) { optionsNotChecked.push(item); }
  });

  return optionsNotChecked;
};

// TODO: SELL-1044
// Is important due to it is applied to the response to the payment_description endpoint
const disableItems = () => {
  const form = document.querySelector('[data-enrollment-engine--checkout-target="checkoutForm"]');

  form.classList.add('disabled');
};

// TODO: SELL-1044
// Is important due to it is applied to the response to the `/payment_description`, `stripe.js`
// and `/payment_installments` endpoint
const enableItems = () => {
  const form = document.querySelector('[data-enrollment-engine--checkout-target="checkoutForm"]');

  form.classList.remove('disabled');
};

export {
  cardCheckoutEnabled,
  fullDiscountApplied,
  ebanxCheckoutEnabled,
  razorpayCheckoutEnabled,
  creditCardSubmitDisabled,
  paymentFormPreventSubmitOnEnterKey,
  enableCheckoutSubmitButton,
  getSelectedPaymentInstallment,
  installmentsNumber,
  disableButton,
  enableButton,
  removeFlashErrorMessage,
  displayFlashErrorMessage,
  stripePaymentIntentCheckoutEnabled,
  transactionUUID,
  paymentInstallmentsNotChecked,
  disableItems,
  enableItems,
};

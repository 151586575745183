import 'regenerator-runtime/runtime';
import Rails from '@rails/ujs';
import * as ActiveStorage from '@rails/activestorage';
import analytics from '../modules/analytics';
import middlewares from '../modules/analytics/middlewares/application';
import enrollmentForms from '../modules/enrollmentForms';
import enrollments from '../modules/enrollments';
import initSentry from '../modules/sentry';
import programs from '../modules/programs/index';
import trackClientEvents from '../modules/trackClientEvents';
import trackServerEvents from '../modules/trackServerEvents';
import validate from '../modules/validate';
import { ebanxGateway } from '../modules/checkout/ebanx';
import { razorpayGateway } from '../modules/checkout/razorpay';
import modals from '../modules/modals';
import getPageChunk from '../modules/lazyChunks';
import { controllerName } from '../modules/routes';
import toggleConsentText from '../modules/registrations/toggleConsentText';
import loadTrackpoint from '../modules/trackpoint/load';
import { trackClickPaymentButtonEvents } from '../modules/checkout/trackPaymentsEvent';

Rails.start();
ActiveStorage.start();
analytics.init(middlewares);
initSentry('Enrollment Engine');
trackClientEvents();
trackClickPaymentButtonEvents();
loadTrackpoint();
ebanxGateway();
razorpayGateway();
validate();
enrollmentForms();
enrollments();
programs();
trackServerEvents();
analytics.page({}, { trackAllPages: true });
modals();
getPageChunk(controllerName(window.location.pathname));
toggleConsentText();

if (document.getElementById('referral_tooltip_link')) {
  getPageChunk('marketing/trackCheckoutSuccessTooltip');
}

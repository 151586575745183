import bootstrapper from '../bootstrapper';

const clientSideFailedPaymentPayload = (provider, error, transactionUUID, numberOfInstallments) => {
  // dueData first part for Enrollment.
  // dueData second part for Payment Installment.
  const dueData = document.querySelector('.js-due-now-amount') || document.querySelector('.js-payment-installment-data');
  const usdPayment = document.getElementById('payment-form').getAttribute('data-usd-payment');
  const payload = {
    amount: dueData.getAttribute('data-amount'),
    currency: dueData.getAttribute('data-currency'),
    error,
    order_id: dueData.getAttribute('data-order'),
    provider,
    transaction_uuid: transactionUUID,
    number_of_installments: numberOfInstallments,
    usd_payment: usdPayment,
  };

  return payload;
};

const createFailedPayment = async (body) => {
  const failedPaymentsUrl = `${window.location.origin}/${bootstrapper.locale}/failed_payments`;
  const CSRFToken = document.querySelector('input[name="authenticity_token"]')?.value;

  return fetch(failedPaymentsUrl, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json; charset=UTF-8',
      'X-CSRF-Token': CSRFToken,
    },
    body: JSON.stringify(body),
  });
};

export {
  clientSideFailedPaymentPayload,
  createFailedPayment,
};

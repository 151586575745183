import {
  isValidatable,
  findOrCreateErrorNode,
  makeDirty,
  removeErrorNode,
} from './domUtil';

const passwordMatch = (options = { id: 'user_password_confirmation', matchId: 'user_password' }) => {
  const input = document.getElementById(options.id);
  const element = document.getElementById(options.matchId);

  if (!element || !input) {
    return false;
  }

  const message = input.getAttribute('data-passwordmismatch') || 'Password confirmation must match password';

  const check = () => {
    if (input.value !== element.value) {
      input.setCustomValidity(message);
      findOrCreateErrorNode({
        input,
        errorMessage: message,
      });
    } else {
      input.setCustomValidity('');
      removeErrorNode(input);
    }
  };

  const handleInvalid = (e) => {
    // Stop the message from displaying
    e.preventDefault();
    check();
  };

  const handleBlur = () => {
    if (!isValidatable(input)) {
      makeDirty(input);
    }
    check();
  };

  const handleInput = () => {
    check();
  };

  input.addEventListener('blur', handleBlur);
  input.addEventListener('input', handleInput);
  input.addEventListener('invalid', handleInvalid);
  element.addEventListener('blur', check);
  element.addEventListener('input', check);
  element.addEventListener('invalid', handleInvalid);

  return true;
};

export default passwordMatch;
